<template>
  <div v-if="ifRender" class="fu-min-form-item">
    <div v-for="(item, index) in fieldJson.paramsLists" :key="`aiphotoField${index}`">
      <Field
        class="no-border fu-min-item-upload"
        :label="item[`${parent.lang}Name`]"
        :name="data.name + path"
        :rules="rule(index)"
      >
        <template #label>
          <span v-if="item.required" style="color: #ee0a24;">*</span> {{ currentLabel(item) }}
        </template>
        <template #input>
          <!-- 示例图 -->
          <VanImage
            v-if="item.sampleSrc"
            class="fu-min-form-upload-sample"
            lazy-load
            fit="contain"
            width="80"
            height="80"
            :src="item.sampleSrc"
            @click="ImagePreview({ images: [item.sampleSrc], closeable: true, showIndex: false })"
          />
          <!-- 上传组件 -->
          <Uploader
            v-if="aiuploader(index) && (!switchBtnIsLivePhotoValue() || aiuploader(index).length)"
            :ref="`uploadRef${index}`"
            :file-list="aiuploader(index)"
            :max-count="fieldJson.maxCount || 1"
            :preview-full-image="true"
            :preview-options="{
              closeable: true
            }"
            :disabled="false"
            :accept="'image/*'"
            v-bind="h5UploaderProps"
            :after-read="file => onAfterRead(file, index)"
            @delete="(e) => deleteImage(e, index)"
          >
            <template #preview-cover="val">
              <VanImage
                fit="cover"
                width="100%"
                height="100%"
                :src="val.url"
                @click="onClickPreview(index)"
              />
            </template>

            <span v-if="parent.isFormReadOnly" class="none" />
          </Uploader>

          <a
            v-else
            class="fu-form-upload-btn"
            href="javascript:"
            @click="onClickUpload(index)"
          >
            <Icon
              :name="'photograph'"
              color="#CBCBCB"
              size="30"
            />
          </a>

        </template>
      </Field>

      <p v-if="aiuploader(index) && aiuploader(index).length && aiuploader(index)[0].hasOwnProperty('valid') && !aiuploader(index)[0].valid && aiuploader(index)[0].message" class="fu-form-aiphoto-validate">
        <!-- <span class="fu-form-aiphoto-valid-fail"></span> -->
        <span style="color: #C82A21; display: block; font-size: 12px;">
          <Icon :name="'warning'" size="12" />
          {{ parent.lang == 'en' ? aiuploader(index)[0].message : aiuploader(index)[0].messageLocal }}
        </span>
        <!-- <span style="color: #C82A21; display: block; font-size: 12px;">{{ $t('livephoto.invalidTips') }}</span> -->
      </p>
    </div>

    <FuFormNote
      v-if="!parent.isFormReadOnly"
      :lang="parent.lang"
      :field-json="fieldJson"
    />

    <Overlay
      v-if="showOverlay"
      ref="overlay"
      :show-overlay="showOverlay"
      :ai-photo-lists="aiPhotoLists"
      :start-index="startIndex"
      :params-lists="fieldJson.paramsLists"
      :loading="loading"
      :lang="parent.lang"
      :vehicle-inspection="fieldJson.vehicleInspection"
      @videoPhotoUpload="videoPhotoUpload"
      @close="closeOverlay"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { Lazyload, ImagePreview, Notify, Toast } from 'vant'
// import Overlay from './overlayLandscape.vue'
import Overlay from './overlayLandscape.vue'
Vue.use(Notify).use(Lazyload).use(ImagePreview)
import { loadScript, devicemotionHandler } from '@/utils/livephoto.js'
import commonMixins from '../mixins/materials'
import displayMixins from '../mixins/display'
import { clone } from 'ramda'
import Dao from '@/utils/dao'
import { Uploader, Icon, Image as VanImage } from 'vant'
import request from '../utils/request'
import axios from 'axios'
import { Session } from '@/utils/storage'
import { inspectionVehicleValidate } from '@/api'
import h5Upload from '../mixins/aiphoto.js'
// import { formatDateId } from '@/filters'

export default {
  name: 'FormItemAiphoto',
  components: { Uploader, Icon, VanImage, Overlay },
  mixins: [commonMixins, displayMixins, h5Upload],
  data() {
    const appInfo = Session.get('appInfo')
    return {
      appInfo,
      toast: null,
      loading: false,
      loadJS: false,
      videoPreviewOverlay: false,
      uploader: [],
      attachmentMetaPath: [],
      attachmentMeta: [],
      ImagePreview,
      currentVideoPreviewUrl: '',

      defaultThumbnailUrl: 'https://static.fuse.com.vn/fu/imgs/MP4.png',
      defaultThumbnailMap: {
        video: 'https://static.fuse.com.vn/fu/imgs/MP4.png',
        pdf: 'https://static.fuse.com.vn/fu/imgs/PDF.png',
        xlsx: 'https://static.fuse.com.vn/fu/imgs/XLSX.png',
        doc: 'https://static.fuse.com.vn/fu/imgs/DOC.png',
        ppt: 'https://static.fuse.com.vn/fu/imgs/PPTX.png',
        file: 'https://static.fuse.com.vn/fu/imgs/FILE.png'
      },

      // 标准文件类型
      fileTypeMap: {
        img: ['.jpeg', '.jpg', '.bmp', '.gif', '.png', '.tiff', '.raw', '.svg'],
        video: ['.mp4', '.mov', '.rmvb', '.avi'],
        pdf: ['.pdf'],
        xlsx: ['.xlsx', '.xls'],
        doc: ['.docx', '.doc'],
        ppt: ['.ppt', '.pptx']
      },
      showOverlay: false,
      aiPhotoLists: [],
      startIndex: 0
    }
  },
  computed: {
    aiuploader() {
      return (index) => {
        if (!this.aiPhotoLists[index]) {
          this.$set(this.aiPhotoLists, index, [])
        }
        return this.aiPhotoLists[index]
      }
    },
    currentLabel() {
      const region = this.appInfo?.region || ''
      return (item) => {
        // return this.parent.lang == 'en' ? item.name : item.local
        if (this.parent.lang == 'en') {
          return item['enName']
        }
        return item[`${region.toLowerCase()}Name`]
      }
    },
    uploadRef() {
      return `${this.currentLabel}Uploader`
    },
    baseApi() {
      const isId = window.location.href.indexOf('f4/boss') > -1
      return isId ? 'f4/api' : ''
    },
    isMaxCount() {
      const maxCount = this.fieldJson.maxCount || 1

      return this.uploader.length >= maxCount
    },
    message() {
      const messageMap = {
        en: 'is required',
        vi: 'bắt buộc',
        th: '*'
      }
      const currentMessage = messageMap[this.parent.lang] || ''
      return currentMessage
    }
  },
  inject: ['switchBtnIsLivePhotoValue'],
  created() {},
  mounted() {
    this.loadOriginUrl()
    this.init()
  },
  methods: {
    isExistScript(url) {
      const existingScript = document.querySelector(`script[src="${url}"]`)
      if (existingScript) {
        console.log(`Script ${url} already loaded`)
        return true
      }
      return false
    },
    async loadOriginUrl() {
      try {
        const packages = [
          // 'https://cdn.fuse.com.vn/fuseProWeb/ort.min-2.js', // 阿里云无法压缩wasm文件，所以注释弃用，改用cdn.jsdelivr.net
          'https://cdn.jsdelivr.net/npm/onnxruntime-web@1.18.0/dist/ort.min.js',
          'https://cdn.fuse.com.vn/fuseProWeb/opencv.js',
          'https://cdn.fuse.com.vn/fuseProWeb/tf.min.js'
        ]
        const needLoad = []
        packages.forEach(item => {
          if (!this.isExistScript(item)) {
            needLoad.push(item)
          }
        })
        if (needLoad.length) {
          this.toast = Toast.loading({
            forbidClick: true,
            loadingType: 'spinner',
            duration: 0
          })
        }
        await Promise.all(needLoad.map(item => {
          return loadScript(item)
        }))
        if (needLoad.length) {
          this.toast && this.toast.clear()
        }
      } catch (error) {
        this.toast && this.toast.clear()
        console.error('Failed to load scripts:', error)
      }
    },
    init() {
      const copyPath = clone(this.currentPath)
      const prevPath = copyPath.splice(0, copyPath.length - 1)
      // const prevPath = copyPath.splice(0, copyPath.length)

      const typePath = [...prevPath, 'attachmentType']
      const labelPath = [...prevPath, 'attachmentTypeLabel']
      const labelLocalPath = [...prevPath, 'attachmentTypeLabelLocal']
      this.attachmentMetaPath = [...prevPath, 'attachmentMeta']

      this.formStore.setFieldValue(typePath, this.fieldJson.attachmentType)
      this.formStore.setFieldValue(labelPath, this.fieldJson.attachmentTypeLabel)
      this.formStore.setFieldValue(labelLocalPath, this.fieldJson.attachmentTypeLabelLocal)
    },

    // 更新表的值
    setCurrentValue(index) {
      const field = this.fieldJson.paramsLists[index].field
      const attachmentMeta = this.aiPhotoLists.map((item, key) => {
        return {
          attachmentPath: item && item.map(i => { return i.fileKey }),
          attachmentFileType: 'image',
          metadata: {},
          field: this.fieldJson.paramsLists[key]?.field || '',
          attachmentType: this.fieldJson.paramsLists[key]?.field || '',
          name: this.fieldJson.paramsLists[key]?.enName || '',
          valid: item && item[0] && item[0].valid,
          message: item && item[0] && item[0]?.message || '',
          messageLocal: item && item[0] && item[0]?.messageLocal || ''
        }
      })
      const currPath = JSON.parse(JSON.stringify(this.currentPath))
      currPath.push(field)
      const value = attachmentMeta[index].attachmentPath
      // console.log(value)
      this.formStore.setFieldValue(currPath, value)
      this.formStore.setFieldValue(this.attachmentMetaPath, attachmentMeta)
    },

    // 点击上传按钮
    onClickUpload(index) {
      this.startIndex = index
      if (!this.switchBtnIsLivePhotoValue()) {
        this.uploadH5V2()
        return
      }
      this.showOverlay = true
      devicemotionHandler(() => {
        this.$refs.overlay && this.$refs.overlay.clearRorateLoading()
      })
    },
    deleteImage(e, index) {
      this.$set(this.aiPhotoLists, [index], [])
    },

    // oss--上传到阿里云
    async uploadToOss(file, isLivePhto) {
      const params = {
        fileNames: [file.name || file.file.name]
      }

      const userInfo = Session.get('appInfo') || Dao.get('userInfo') || {}
      // const url = `${this.baseApi}/pro/document/store/upload/getUrl`
      let url
      let token
      if (this.$route.name == 'ClaimForm') {
        token = Dao.get('claimToken')
        url = `${this.baseApi}/claim/temp/form/store/upload/getUrl`
      } else {
        token = Dao.get('inspectionToken')
        url = `${this.baseApi}/local/inspection/store/upload/getUrl`
      }

      const res = await request({
        url: url,
        method: 'post',
        data: params,
        headers: {
          appCode: 'IDP_BOSS',
          fusetoken: userInfo.token,
          'x-5a-temp-token': token ?? ''
        }
      })

      // 接口异常则终止
      if (!res) { return {} }
      // 上传阿里云
      const ossBackData = res[0]

      const fileBuffer = await this.getArrayBuffer(isLivePhto ? file : file.file)

      const putRes = await this.put(ossBackData.uploadUrl, fileBuffer, ossBackData.headers)

      if (putRes.status == 200) {
        return { result: 'ok', fileKey: ossBackData.fileKey, fileUrl: ossBackData.fileUrl }
      } else {
        return {}
      }
    },

    // ArrayBuffer 转 Base64
    getBase64(file) {
      return window.btoa(new Uint8Array(file).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        '')
      )
    },

    // File 转 ArrayBuffer
    getArrayBuffer(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsArrayBuffer(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },

    // base64 转 File
    base64ConvertFile(dataUrl) {
      if (typeof dataUrl != 'string') {
        this.$toast('dataUrl 不是字符串')
        return
      }

      const arr = dataUrl.split(';base64,') // [data:image/png,xxx]
      const mime = arr[0].replace('data:', '') // mime后端识别用的文件格式数据
      // const fileType = mime.split('/').pop() // 获取文件格式
      const bstr = atob(arr[1]) // base64解码
      // 创建Unicode编码的数组对象，每个值都是Unicode
      const u8arr = new Uint8Array(bstr.split('').map(str => str.charCodeAt(0)))

      const blob = new Blob([u8arr], { type: mime })

      blob.lastModifiedDate = new Date()

      // return blob
      const file = new File([blob], 'fileName.jpg', { type: 'image/jpg' })
      return file
    },

    put(url, data, headers) {
      return new Promise((resolve, reject) => {
        axios.put(url, data, { headers: headers }).then(response => (
          resolve(response)
        )).catch(err => {
          reject(err.data)
        })
      })
    },

    onClickPreview(index) {
      const images = this.aiPhotoLists[index].map(item => {
        return item.url
      })
      ImagePreview(images)
    },

    // 根据文件路径获取 扩展名
    getFileExtension(url, abbreviation = false) {
      const urlObj = new URL(url)
      const pathname = urlObj.pathname // 获取路径部分

      // 从路径中获取文件名
      const fileName = pathname.substring(pathname.lastIndexOf('/') + 1)

      // 获取文件扩展名
      const fileExtension = fileName.split('.').pop()

      if (!abbreviation) {
        return `.${fileExtension}`
      } else {
        const type = Object.keys(this.fileTypeMap).filter(key => {
          return this.fileTypeMap[key].includes(fileExtension.toLowerCase())
        })

        return type[0] || `.${fileExtension}`
      }
    },
    closeOverlay() {
      this.$nextTick(() => {
        this.showOverlay = false
        this.$refs.overlay.closeMediaStream()
      })
    },

    // Live photo
    async videoPhotoUpload(url, index) {
      this.$set(this.aiPhotoLists, [index], [{ url: url, status: 'uploading' }])
      const file = this.base64ConvertFile(url)
      this.loading = true
      const res = await this.uploadToOss(file, true)

      if (!res || res.result !== 'ok') { return }
      const newObj = {
        fileKey: res.fileKey || '',
        url: res.fileUrl,
        fileUrl: res.fileUrl,
        status: res.fileKey ? 'done' : 'failed'
      }
      this.$set(this.aiPhotoLists, [index], [newObj])
      this.setCurrentValue(index)
      // if (this.$refs.overlay) this.$refs.overlay.img = ''
      if (this.fieldJson.switchApiCheck) {
        const hasItem = this.aiPhotoLists.filter(item => item?.[0]?.fileKey)
        if (hasItem.length == this.aiPhotoLists.length) {
          await this.asyncinspectionVehicleValidate(index, res.fileKey)
        } else {
          this.asyncinspectionVehicleValidate(index, res.fileKey)
        }
      }
      this.loading = false
      if (this.aiPhotoLists.every(item => item?.[0]?.fileKey)) {
        this.closeOverlay()
      }
      if (this.startIndex + 1 < this.fieldJson.paramsLists.length) {
        this.startIndex++
        return
      }
      this.closeOverlay()
    },
    async asyncinspectionVehicleValidate(index, fileKey) {
      this.inspectionInfo = Dao.get('inspectionInfo')
      // const token = Dao.get('inspectionToken')
      const params = {
        'businessId': this.inspectionInfo?.shareInspectionInfo?.orderSlipInspectionDTO?.slipUid,
        'businessType': 'CAR_INSPECTION',
        'fileKey': fileKey,
        'label': this.fieldJson.paramsLists[index].apiLabel
      }
      const res = await inspectionVehicleValidate(params)
      let newObj = this.aiPhotoLists[index][0]
      newObj = { ...newObj, ...res }
      this.$set(this.aiPhotoLists, [index], [newObj])
      this.setCurrentValue(index)
      return Promise.resolve(true)
    },
    rule(index) {
      const message = `${this.fieldJson.paramsLists[index][`${this.parent.lang}Name`]} is required`
      const required = this.fieldJson.paramsLists[index].required
      return [
        {
          validator: (val) => {
            if (!required) return true
            if (!this.aiPhotoLists?.[index]?.[0]) return false
            return true
          },
          message: message,
          trigger: 'change'
        }
      ]
    },
    // 传统上传
    async fileUpload(file, index) {
      this.$set(this.aiPhotoLists, [index], [{ url: '', status: 'uploading' }])
      this.loading = true
      const res = await this.uploadToOss(file, false)

      if (!res || res.result !== 'ok') { return }
      const newObj = {
        fileKey: res.fileKey || '',
        url: res.fileUrl,
        fileUrl: res.fileUrl,
        status: res.fileKey ? 'done' : 'failed'
      }
      this.$set(this.aiPhotoLists, [index], [newObj])
      this.setCurrentValue(index)
      // if (this.$refs.overlay) this.$refs.overlay.img = ''
      if (this.fieldJson.switchApiCheck) {
        const hasItem = this.aiPhotoLists.filter(item => item?.[0]?.fileKey)
        if (hasItem.length == this.aiPhotoLists.length) {
          await this.asyncinspectionVehicleValidate(index, res.fileKey)
        } else {
          this.asyncinspectionVehicleValidate(index, res.fileKey)
        }
      }
      this.loading = false
      if (this.aiPhotoLists.every(item => item?.[0]?.fileKey)) {
        this.closeOverlay()
      }
      if (this.startIndex + 1 < this.fieldJson.paramsLists.length) {
        this.startIndex++
        return
      }
      this.closeOverlay()
    },
    // 选取文件后的回调
    async onAfterRead(file, index) {
      this.fileUpload(file, index)
    }
  }
}
</script>

<style lang="scss" scoped>

.preview-overlay {
  display: flex;
  align-items: center;
}
.overlay-close-btn {
  position: absolute;
  top: .2rem;
  right: .2rem;
}

.fu-form-upload-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 80PX;
  height: 80PX;
  border: 2px dashed #ebedf0;
  box-sizing: border-box;
}
.fu-form-aiphoto-validate {
  margin: 0px 0.26667rem;
  padding: 10px 10px;
  background-color: #ffdec7;
  color: #ea7e1b;
  border-radius: 5px;
  font-size: 12px;
  .fu-form-aiphoto-valid-failed {
    color: #C82A21;
    font-size: 12px;
  }
}

.none {
  display: none;
}

</style>
